import React from "react";
import NorthstarPageLayout from "../../components/NorthstarPageLayout";
import headerBackground from "../../assets/northstar/header_bg.png"
import howWeHelp from "../../assets/northstar/how_we_help.png";
import chartLogo from "../../assets/northstar/chart_line.png"
import teamLogo from "../../assets/northstar/team.png"
import applicationLogo from "../../assets/northstar/application.png"
import pc from "../../assets/northstar/pc.png";
import eo from "../../assets/northstar/eo.png"
import yc from "../../assets/northstar/yc.png"
import cf from "../../assets/northstar/cf.png"
import { EnvelopeIcon } from "@heroicons/react/24/outline";


const resourcesPage: React.FC = () => {
    return (
        <NorthstarPageLayout title="Resources">
            <>
                <div
                    className="mb-14 w-full h-36 rounded"
                    style={{
                        backgroundImage: `url(${headerBackground})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "bottom"
                    }}
                >
                    <div className="p-3">
                        <div className="text-blue text-xl font-title">{"Resources"}</div>
                        <div className="text-gray-dark text-xs md:text-sm"> See how our experts in Data Science, Go-to-Market, and Talent are helping our portfolio companies.</div>
                    </div>
                </div>
                <div>
                    <div className="flex pb-4 border-b border-b-gray-light items-center">
                        <img src={chartLogo} className="h-7"></img>
                        <div className="ml-3 font-semibold font-title text-lg">How we help</div>
                    </div>
                    <div className="my-14">
                        <img src={howWeHelp} className="h-[300px] lg:h-[400px]"></img>
                    </div>
                </div>
                <div>
                    <div className="flex pb-4 border-b border-b-gray-light items-center">
                        <img src={teamLogo} className="h-7"></img>
                        <div className="ml-3 font-semibold font-title text-lg">Contacts</div>
                    </div>
                    <div className="flex mt-6 gap-6 flex-col xl:flex-row pb-4">
                        <div className="flex border border-off-white hover:border-orange rounded p-4 items-center">
                            <img src={cf} className="h-20 rounded-full"></img>
                            <div className="flex flex-col justify-center ml-4">
                            <div className="font-semibold flex justify-between">
                                    <span>Charlie Flanagan</span>
                                    <a href="mailto:charlie@bamelevate.com"><EnvelopeIcon className="text-blue-light h-[1.2rem] cursor-pointer"></EnvelopeIcon></a>
                                </div>
                                <div className="text-sm">
                                    Head of Data Science, New York
                                </div>
                            </div>
                        </div>
                        <div className="flex border border-off-white hover:border-orange rounded p-4 items-center">
                            <img src={eo} className="h-20 rounded-full"></img>
                            <div className="flex flex-col justify-center ml-4">
                            <div className="font-semibold flex justify-between">
                                    <span>Elizabeth Ocken</span>
                                    <a href="mailto:elizabeth@bamelevate.com"><EnvelopeIcon className="text-blue-light h-[1.2rem] cursor-pointer"></EnvelopeIcon></a>
                                </div>
                                <div className="text-sm">
                                    Operating Director, San Francisco
                                </div>
                            </div>
                        </div>
                        <div className="flex border border-off-white hover:border-orange rounded p-4 items-center">
                            <img src={pc} className="h-20 rounded-full"></img>
                            <div className="flex flex-col justify-center ml-4">
                                <div className="font-semibold flex justify-between">
                                    <span>Perri Chilton</span>
                                    <a href="mailto:perri@bamelevate.com"><EnvelopeIcon className="text-blue-light h-[1.2rem] cursor-pointer"></EnvelopeIcon></a>
                                </div>
                                <div className="text-sm">
                                    Operating Director, Talent, New York
                                </div>
                            </div>
                        </div>
                        <div className="flex border border-off-white hover:border-orange rounded p-4 items-center">
                            <img src={yc} className="h-20 rounded-full"></img>
                            <div className="flex flex-col justify-center ml-4">
                                <div className="font-semibold flex justify-between">
                                    <span>Yujin Chung</span>
                                    <a href="mailto:yujin@bamelevate.com"><EnvelopeIcon className="text-blue-light h-[1.2rem] cursor-pointer"></EnvelopeIcon></a>
                                </div>
                                <div className="text-sm">
                                    Operating Director, Go to Market, San Francisco
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="flex pb-4 border-b border-b-gray-light items-center">
                        <img src={applicationLogo} className="h-7"></img>
                        <div className="ml-3 font-semibold font-title text-lg">Resources</div>
                    </div>
                    <div className="flex mt-6">
                        <h1>Under construction &#128522;</h1>
                    </div>
                </div>
            </>
        </NorthstarPageLayout>
    )
}

export default resourcesPage;